.footer{
    width: 105%;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.486);
}
.logo-footer{
    max-height: 40px;
}
.network{
    list-style-type: none;
    margin:0;
    display: flex;
    justify-content: space-around;
}
.network-logo{
    max-width: 25%;
    margin: 0 10% 0 0;

}

.p-footer{
    margin:0;
    color:rgb(48, 45, 45);
    font-size: 1.5rem;
    font-weight: 550;
}
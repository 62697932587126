h2{
    padding: 3%;
    width: 95%;
    font-family: 'Microsoft JhengHei','Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color:white;
    font-size: calc(1vw + 2.6rem);
    margin:0;
    box-sizing: border-box;
    text-align: left;
    font-weight: 400;
}
.p-about-me{
    box-sizing: border-box;
    margin:0 ;
    padding: 3%;
    width: 80%;
    font-size: 1.8rem;
    color:white;
    text-align: left;
    background-color: rgba(0, 0, 0, .2);
    line-height: 3rem;
    
}
.h-about-me{
    width: 100%;
    margin: 5% 0 0 0;
    text-align: center;
    
}
.about-me{
    background-image: url('../assets/aboutMe-background-mobile.png');  
    background-size: cover;
    background-position: bottom right;
}
.about-me-container{
    height: 65%;
    max-width: 1500px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.ab-gradient{
    background: linear-gradient(0deg, rgba(39,12,12,0.6) 0%, rgba(255, 255, 255, 0) 100%);
    display: flex;
    justify-content: space-around;

}
.profilePict{
    width: 20%;
    max-width: 100px;
    border-radius:41% 48% 47% 41% / 41% 43% 41% 43% ;
    margin:0% 0% 3% 0;
    box-shadow: rgba(255, 255, 255, 0.815) -1px -1px 2px,
    black 1px -1px 7px;
}
@media (min-width: 650px){
    .about-me{
        background-image: url('../assets/aboutMe-background-desktop.png');  
        background-position: bottom right;
    }
    .about-me-container{
        width: 90%;
        flex-direction: row;
        flex-wrap: wrap;
        position: relative;
        top:10%;
        justify-content: space-around;
        align-content: flex-start;
    }
    .h-about-me{
        width: 60%;
        text-align: start;
        align-self: flex-start;
        
    }
    .profilePict{
        align-self: flex-end;
        position: relative;
        bottom:-5%;
        left: -35%;
        box-shadow: rgb(61, 55, 55) 5px -5px 20px,
    rgb(75, 54, 8) 1px -1px 7px,
    rgba(255, 255, 255, 0.815) 1px -1px 20px;
    }
    h2{
        font-family: Stencil,'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        color:black;
    }
    .ab-gradient{
        background: linear-gradient(0deg, rgba(39, 12, 12, 0.3) 0%, rgba(255, 255, 255, 0) 100%);
    
    }
    .p-about-me{
        background-color: rgba(255, 255, 255, 0.2);
        color:black;
        margin: auto;
        padding: 2rem;
    }
}
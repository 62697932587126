html{
    font-size: 10px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    overflow-x: hidden;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
}
.app section{
    scroll-snap-align: start;
    scroll-snap-stop:always;
    scroll-behavior: smooth;
    /* transition: all 1000ms ease 0s; */
    min-height: 600px;

}
.ab-gradient,
.skills-gradient,
.git-gradient
{
    min-height: 600px;
}
.app{
    transition: all 1000ms ease 0s;
}
a{
    text-decoration: none;
}
.nav{
    z-index: 2;
}
.footer{
    z-index: 3;
}

@media (min-width: 650px) {
    html{
    scroll-snap-type: y mandatory;
    }
}
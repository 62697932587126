.nav-o{
    position:fixed;
    right: 0;
    padding: 0;
    height: 150px;
    width: 250px;
    display: flex;
    align-items: center;
    background-color: rgba(121, 109, 109, 0.705);
    backdrop-filter:blur(10px);
    z-index: 1;
    -webkit-backdrop-filter: blur(15px); 
    backdrop-filter: blur(15px);
    box-shadow: -3px 3px 7px rgba(37, 34, 34, 0.856);
    transition: all .2s ease;
}
.closed-click{
    position:fixed;
    overflow: hidden;
    z-index: 6;
}
.nav{
    position:fixed;
    right: 0;
    padding: 0;
    height: 150px;
    display: flex;
    align-items: center;
    transition: all .2s ease;
}
.h-item-o{
    font-size: calc(12px + 1vh);
    margin:5px;
    color:rgb(53, 42, 42);
    text-shadow: 1px 1px .5px rgba(180, 171, 171, 0.5);
    z-index: 5;
}
.h-item{
    font-size: calc(8px + 1vh);
    margin:3px;
    color:rgb(255, 255, 255);
    background: linear-gradient(90deg, rgba(70, 55, 55, 0.842) 0%, rgba(54, 46, 46, 0.74) 49%, rgba(58, 56, 56, 0.253) 100%);
    text-shadow: 1px 1px 5px rgba(235, 157, 157, 0.5);
    z-index: 5;
}
nav ul{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    min-height: 25%;
    justify-content: space-between;
    list-style-type: none;
    width: 100%;
    position: relative;
    padding: 0;
}
.not-o{
    align-items: flex-end;
}
.h-nav-o{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 20px;
    flex-direction: row;
    align-items: center;
    border:rgba(255, 255, 255, 0.308) solid;
    background-color: rgba(255, 255, 255, 0.322);
    border-radius: 10% 0% 0% 10% / 100% 0% 0% 100%;
    font-size: 23px;
    margin: 2px;
    z-index: 3;
}
.circle-o{
    border:rgb(53, 42, 42) solid;
    background-color: rgb(53, 42, 42);
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin:24px;
    z-index: 4;
}
.circle{
    border:rgb(53, 42, 42) solid;
    background-color: rgb(53, 42, 42);
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin:10px;
    z-index: 4;
}
.zIndex{
    z-index: 10;
    display: flex;
    margin: 0 10px 0 0 ;
}

.nav-tab{
    position: absolute;
    margin: 0 10px 0 0;
    background-color: rgba(255, 255, 255, 0.322);
    right: 4.5px;
    width: 20px;
    height: 100%;
    border-radius: 100% 100% 100% 100% / 10% 10% 10% 10%;
    z-index: 2;
    box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.274);
}
@media (min-width: 650px){
   
}
.get-in-touch{
    background-image: url('../assets/skills-background-mobile.png');  
    background-size: cover;
    background-position: top left;
    text-align: center;

}
.h-get-in-touch{
    text-align: center;
    margin: 5% 0 0 0;
}
.git-gradient{
    background: linear-gradient(0deg, rgba(39,12,12,0.6) 0%, rgba(255, 255, 255, 0) 100%);
    background-position: bottom right;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:flex-start;
}
form{
    display: flex;
    width: 80%;
    max-width: 630px;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    background-color: rgba(255, 255, 255, 0.425);
    box-shadow: 1px 1px 5px rgb(134, 103, 103);
    margin: 10px 0;
}
label{
    font-size: min(max(23px, 1vw),calc(1vw + 1vh + .6rem));
    text-align: left;
    margin: 5% 0 .5% 0;
    color: rgb(34, 24, 24);
    font-weight: 600;
}
input{
    width: 100%;
    height: 3rem;
}
textarea{
    width: 100%;
    height: 15rem;
    overflow-y: auto;
}
.inter-form{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
button{
    border-radius: 50%  ;
    background: linear-gradient(180deg, rgba(219, 233, 196, 0.705) 0%, rgb(161, 138, 138) 60%);
    width: 18vw;
    max-width: 110px;
    aspect-ratio: 1/1;
    margin:10px;
    box-shadow: -1px 5px 10px rgb(7, 7, 7);
}
.img-send{
    width: 14vw;
    max-width: 90px;
    aspect-ratio: 1/1;
    margin: 0 8px 0 0;
}
button:hover{
    box-shadow: 5px 5px 11px rgb(255, 255, 255);
}
.form{
    display: flex;
    flex-direction: column;
    width: 75%;
    height: 75%;
}

@media (min-width: 650px){
    .get-in-touch{
        background-image: url('../assets/skills-background-desktop.png');  
        background-size: cover;
        background-position: bottom center;
        text-align: center;
    }
    .h-get-in-touch{
        margin: 0;
    }
    form{
        margin:0;
    }
    textarea{
    width: 100%;
    height: 13rem;
    overflow-y: auto;
}
}
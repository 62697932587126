.skill-item{
    display: flex;
    flex-wrap: wrap;
    min-width:130px;
    width: 80%;
    aspect-ratio: 2/1;
    font-size: calc(10px + 0.3vw);
    font-weight: 350;
    border-radius: 20% 20% 20% 20% / 35% 35% 35% 35%  ;
    background: linear-gradient(180deg, rgba(255,255,255,0.2) 0%, rgba(151, 126, 126, 0.7) 60%);
    list-style-type: none;
    box-shadow: 5px 5px 11px rgb(17, 24, 17);
    margin:auto;
}
.skill-item:hover{
    box-shadow: 5px 5px 11px rgb(255, 255, 255);
}
.skill-progress{
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-self: auto;
}
.skill-name{
    height: 25%;
    width: 120%;
    margin: 0 0 35% 0 ;
    position: relative;
    top:15%;
    font-weight: 600;
    text-align: left;
    text-shadow: rgb(255, 255, 255) 1px 2px 7px,
    rgb(255, 255, 255) 1px -2px 7px;
}
.skill-item-logo{
    display: block;
    max-height: 80%;
    width: 50%;
    margin:4% 0 0 0 ;
    object-fit: contain;
}
.skill-container-state{
    border: solid 1px;
    width: 100%;
    height: 20%;
    align-items: flex-start;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content:center;


}
.skill-state{
    height: 70%;
    margin:2%;
    background: linear-gradient(0deg, rgba(27,117,8,1) 50%, rgba(132, 218, 99, 0.748) 100%);
}
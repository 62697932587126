.skills{
    background-image: url('../assets/skills-background-mobile.png');  
    background-size: cover;
    background-position: top left;
    text-align: center;
}
.skills-gradient{
    background: linear-gradient(0deg, rgba(39,12,12,0.6) 0%, rgba(255, 255, 255, 0) 100%);
    background-position: bottom right;
}
.h-skills{
    text-align: center;
}
.skills-list{
    max-width: 90%;
    min-height: 60%;
    max-height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
}
.skill-list{
    min-width:130px;
    width: 17%;
    aspect-ratio: 2/1;
    margin:auto 5% 5% auto;
}


@media (min-width: 650px){
    .skills{
        background-image: url('../assets/skills-background-desktop.png');  
        background-size: cover;
        background-position: bottom center;
        text-align: center;
    }
    .skills-list{
        width: 100%;
        max-width: 95%;    
    }
}

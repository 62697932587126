
/* style development  */
h1{
    position: relative;
    top:8%;
    font-size: 2.7rem;
    text-align: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    letter-spacing: 0.7rem;
    line-height: 4rem;
    color:rgba( 255, 226, 226, 1);
    font-weight: 500;
    margin: 0;
    text-shadow: 1px -2px 2px rgba(0, 0, 0, 0.2),
                60px 8px 8px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
}
h1 span{
    text-shadow: 2px -20px 2px rgba(0, 0, 0, 0.1),
    -30px 3px 5px rgba(0, 0, 0, 0.2),
    60px 8px 8px rgba(0, 0, 0, 0.3);
}
.home{
    background-image: url('../assets/home-background-mobile.png');  
    background-size: cover;
    background-position: bottom right;
    text-align: center;
    display: flex;
    justify-content: center;
}

@media (min-width: 650px) {
    /* style development  */
    .home{
        background-image: url('../assets/home-background-desktop.png');  
        background-position: bottom center;
    }
    h1{
        left:unset;
        top:40%;
        width: 100%;
        height: 10%;
        color:rgba(85, 61, 61,1);
        font-weight: 650;
        font-size: 4.5em;
        text-shadow: -60px -25px 8px rgba(0, 0, 0, 0.2);
        letter-spacing: 0.8rem;
    }
    h1 span{
        text-shadow: -60px -25px 8px rgba(0, 0, 0, 0.2),
        -60px 25px 8px rgba(0, 0, 0, 0.1),
        0px 5px 8px rgba(0, 0, 0, 0.1);
    }
}
